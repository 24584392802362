import Vue from 'vue'
import VueRouter from 'vue-router'
import Book from "@/vues/Book";
import Reception from "@/vues/Reception";
import Activities from "@/vues/Activities";
import Activity2021GurrazStage from "@/components/Activity2021GurrazStage"
import Activity2020JulyStage from "@/components/Activity2020JulyStage";
import Activity2021VoglansStage from "@/components/Activity2021VoglansStage";
import Activity2019DiscoveringClass from "@/components/Activity2019DiscoveringClass";
import Activity2018Students from "@/components/Activity2018Students";
import Activity2017DiscoveringClassBeaufort from "@/components/Activity2017DiscoveringClassBeaufort";
import Activity2016DiscoveringClassLanslebourg from "@/components/Activity2016DiscoveringClassLanslebourg";

Vue.use(VueRouter)


const routes = [
    {
        path: "/",
        name: "Reception",
        component: Reception,
    },
    {
        path: "/Book",
        name: "Book",
        component: Book,
    },
    {
        path: "/Activities",
        name: "Activities",
        component: Activities,
    },
    // {
    //     path: "/GoldenBook",
    //     name: "GoldenBook",
    //     component: GoldenBook,
    // },
    {
        path: "/2020JulyStage",
        name: "2020JulyStage",
        component: Activity2020JulyStage,
    },
    {
        path: "/2021VoglansStage",
        name: "2021VoglansStage",
        component: Activity2021VoglansStage,
    },
    {
        path: "/Activity2019DiscoveringClass",
        name: "Activity2019DiscoveringClass",
        component: Activity2019DiscoveringClass,
    },
    {
        path: "/Activity2018Students",
        name: "Activity2018Students",
        component: Activity2018Students,
    },
    {
        path: "/Activity2017DiscoveringClassBeaufort",
        name: "Activity2017DiscoveringClassBeaufort",
        component: Activity2017DiscoveringClassBeaufort,
    },
    {
        path: "/Activity2016DiscoveringClassLanslebourg",
        name: "Activity2016DiscoveringClassLanslebourg",
        component: Activity2016DiscoveringClassLanslebourg,
    },
    {
        path: "/Activity2021GurrazStage",
        name: "Activity2021GurrazStage",
        component: Activity2021GurrazStage,
    },













// -------------------- Error 404, at the end -------------------- //
    {
        path: '/:pathMatch(.*)*',
        component: () => import("@/vues/UrlSyntaxError"),
    },
]

const router = new VueRouter({
    routes: routes,
    mode: "history", // retour aux pages précédentes sans avoir à recharger les pages
    base: process.env.BASE_URL, // Clean root routes
})

export default router;