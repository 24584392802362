<template>
  <v-app-bar app class="orange" height="100"
             style="z-index: 150; padding: 0px;">
      <v-img
          alt="flowerIcon"
          class="shrink mr-2 image--shadow"
          src="@/assets/Giraffe.png"
          transition="scale-transition"
          min-width="40"
          max-width="60"
          @click="$router.replace('/')"
      />
      <span @click="$router.replace('/')" class="yellow--text text--lighten-3">Muriel Veillas</span>

    <v-spacer></v-spacer>
    <v-row     v-if="this.$vuetify.breakpoint.width > 1250 ">
      <v-col cols="4">
        <v-btn width="15%" large min-width="150px" class="ml-5 mr-5" to="/">
          <v-hover>Accueil</v-hover>
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn width="15%" large min-width="150px" class="ml-5 mr-5" to="/Activities">
          Mes activités
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-btn width="15%" large min-width="150px" class="ml-5 mr-5" to="/Book">
          Mon Book
        </v-btn>
      </v-col>
<!--      <v-col cols="3">-->
<!--        <v-btn width="15%" large min-width="150px" class="ml-5 mr-5" to="/GoldenBook">-->
<!--          Livre d'or-->
<!--        </v-btn>-->
<!--      </v-col>-->
    </v-row>

    <v-col v-else>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              style="height: 44px;
              min-width: 60px;"
          >
            Découvrez...
          </v-btn>
        </template>

        <v-list
        v-for="item in menu"
        :key="item.name">
          <v-list-item :to="item.goToPage" >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-col>

    <v-spacer></v-spacer>

    <v-btn
        text
        class="mr-2"
        @click="$vuetify.goTo(99999, options)"
    ><span class="mr-2 yellow--text text--lighten-3" v-if="this.$vuetify.breakpoint.width > 800">Contact</span>
      <v-icon class="yellow--text text--lighten-3">mdi-phone</v-icon>
      <v-icon class="yellow--text text--lighten-3">mdi-email</v-icon>

    </v-btn>
    <v-img
        class="image--shadow"
        v-if="this.$vuetify.breakpoint.width > 465"
        max-height="80"
        max-width=80
        src="@/assets/Her2.png"
    ></v-img>
  </v-app-bar>
</template>

<script>
export default {
  name: "NavBar",

  computed: {
    options() {
      return {
        duration: 4000,
      }
    },
  },

  data: () => ({
    menu: [
      {name:'Qui suis-je ? ',goToPage: '/'},
      {name:'Mes Activités',goToPage: '/Activities'},
      {name:'Mon Book',goToPage: '/Book'},
      // {name:"Livre d'Or",goToPage: '/GoldenBook'},
    ],
  }),
}
</script>



<style scoped>
.v-image.image--shadow{

  box-shadow: 0px 4px 4px -5px rgba(0, 0, 0, 0.2), 0px 10px 12px 2px rgba(0, 0, 0, 0.14), 0px 3px 14px 3px rgba(0, 0, 0, 0.12);

}
</style>