<template>

<!--  BACK TO ACTIVITIES  -->

    <v-row>
      <v-col cols="6">
        <v-card elevation="5" class="align-center mt-n2" color="#7CB342" dark min-width="210" width="210" style="z-index: 100; position: fixed;">
          <v-card-text>Cliquez sur les images <v-icon class="ml-2">mdi-image</v-icon> </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" align="right" class="ml-n16">
        <v-btn color="blue" elevation="5" style="z-index: 100" class="white--text ml-n5 mt-2" fixed to="/Activities" >
          Retour
        </v-btn>
      </v-col>
    </v-row>
</template>

<script>
export default {
  name: "BTActivities"
}
</script>

<style scoped>

</style>