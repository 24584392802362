<template>
  <v-container>

    <Activity
        :imgsThis1="imgsThis1"
        :imgsThis2="imgsThis2"
        :images="images"
        text="UN STAGE  de trois jours avec diverses  techniques au programme : mélanges des couleurs, dessin facile, sanguine, étude de l’eau avec un fruit, et enfin découverte de la création de Mondes à la bombe."
    />

  </v-container>
</template>

<script>
import Activity from "@/components/Activity";

export default {
  name: "Activity2020JulyStage",
  data() {
    return {
      imgsThis1: {
        url: require("@/assets/activities/2020/2020JulyStage/5.jpg"),
        lazy_url: require("@/assets/activities/2020/2020JulyStageL/5.jpg"),
        name: "",
      },
      imgsThis2: {
        url: require("@/assets/activities/2020/2020JulyStage/4.jpg"),
        lazy_url: require("@/assets/activities/2020/2020JulyStageL/4.jpg"),
        name: "",
      },
      images: [
        {
          url: require("@/assets/activities/2020/2020JulyStage/3.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/3.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/2.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/2.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/6.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/6.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/1.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/1.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/7.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/7.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/8.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/8.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/9.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/9.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/10.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/10.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/11.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/11.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/12.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/12.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/13.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/13.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/14.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/14.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/15.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/15.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/16.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/16.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/17.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/17.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/18.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/18.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/19.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/19.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/20.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/20.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/21.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/21.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/22.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/22.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/23.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/23.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/24.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/24.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/25.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/25.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/26.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/26.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/27.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/27.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/28.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/28.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/29.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/29.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/30.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/30.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/31.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/31.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/32.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/32.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/33.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/33.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/34.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/34.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/35.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/35.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/36.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/36.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/37.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/37.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/38.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/38.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/39.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/39.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/40.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/40.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/41.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/41.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/42.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/42.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/43.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/43.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/44.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/44.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/45.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/45.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/46.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/46.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/47.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/47.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/48.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/48.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2020/2020JulyStage/49.jpg"),
          lazy_url: require("@/assets/activities/2020/2020JulyStageL/49.jpg"),
          name: "",
        },


      ],
    }
  },
  components: {
    Activity,
  },
  mounted() {
    this.$vuetify.goTo(0);
  },
}
</script>

<style scoped>

</style>