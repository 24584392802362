<template>
  <v-container>

    <Activity
        :imgsThis1="imgsThis1"
        :imgsThis2="imgsThis2"
        :images="images"
        text="Depuis 2013 je travaille en collaboration avec l’Association Savoyarde des Classes de Découvertes qui organise des séjours pour les écoles. Pour celle-ci, c’est la découverte de la peinture baroque qui est à l’honneur. En plus, on a vu plein de marmottes !"
    />

  </v-container>
</template>

<script>
import Activity from "@/components/Activity";

export default {
  name: "Activity2019DiscoveringClass",
  data() {
    return {
      imgsThis1: {
        url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/01.jpg"),
        lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L01.jpg"),
        name: "",
      },
      imgsThis2: {
        url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/11.jpg"),
        lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L11.jpg"),
        name: "",
      },
      images: [
        {
          url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/02.jpg"),
          lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L02.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/03.jpg"),
          lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L03.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/04.jpg"),
          lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L04.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/05.jpg"),
          lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L05.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/06.jpg"),
          lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L06.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/07.jpg"),
          lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L07.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/08.jpg"),
          lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L08.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/09.jpg"),
          lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L09.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/10.jpg"),
          lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L10.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/11.jpg"),
          lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L11.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/12.jpg"),
          lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L12.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/13.jpg"),
          lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L13.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/14.jpg"),
          lazy_url: require("@/assets/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L14.jpg"),
          name: "",
        },

      ]
    }
  },
  components: {
    Activity,
  },

  mounted() {
    this.$vuetify.goTo(0);
  },
}
</script>

<style scoped>

</style>