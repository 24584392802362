<template>
 <v-container>
   <BTActivities/>


   <!--   PC version   -->

   <v-container v-if="this.$vuetify.breakpoint.width > 1000">
     <v-row
         class="mt-8"
         align="center">
       <v-col
           align-self="center"
           cols="3"
           align="center"
       >
         <Modal :image="imgsThis1"/>
       </v-col>
       <v-col
           align-self="center"
           cols="6"
           align="center">
         <v-card class="pa-4"><h2>{{ text }}</h2></v-card>
       </v-col>
       <v-col
           align-self="center"
           cols="3"
           align="center"
       >
         <Modal :image="imgsThis2"/>
       </v-col>
     </v-row>


     <v-row
         class="mt-16"
         align="center"

     >
       <v-col

           align-self="center"
           cols="3"
           align="center"
           v-for="image in images"
           :key="image.lazy_url"

       >
         <Modal :image="image"/>

       </v-col>

     </v-row>
   </v-container>

   <!--    mobile    -->

   <v-container v-else class="mt-15">
     <v-card class="mb-4"><h4>{{text}}</h4></v-card>
     <v-row
         align="center"
         justify="space-around"
     >
       <v-col cols="5">
         <Modal :image="imgsThis1"/>
       </v-col>
       <v-col cols="5">
         <Modal :image="imgsThis2"/>
       </v-col>
       <v-col
           cols="5"
           align="center"
           v-for="image in images"
           :key="image.lazy_url"
       >
         <Modal :image="image"/>

       </v-col>

     </v-row>
   </v-container>

 </v-container>
</template>

<script>
import BTActivities from "@/components/BTActivities";
import Modal from "@/components/Modal";

export default {
  name: "Activity",
  components:{
    BTActivities,
    Modal,
  },
  props:[
      'imgsThis1',
      'imgsThis2',
      'images',
      'text',

  ]

}
</script>

<style scoped>

</style>