<template>
  <v-footer class="mt-8"
      padless
  >
    <v-card
        flat
        tile
        width="100%"
        class="indigo lighten-1 text-center"
    >
      <v-card-text class="indigo lighten-2 white--text" >
        <v-icon size="24px" class="white--text">
          mdi-phone
        </v-icon>
        <span class="mr-3"> 06 78 41 82 33 </span>

        <v-icon size="24px" class="white--text">
          mdi-email
        </v-icon>
        <span> mu.veillas@gmail.com </span>

        <v-icon size="24px" class="white--text mr-1 ml-3">
          mdi-facebook
        </v-icon>

        <a
            href="https://www.facebook.com/Muriel-Veillas-artiste-peintre-104867251293825"
            target="_blank"
            rel="noopener noreferrer"
        >

          <span class="white--text">Muriel Veillas · Artiste Peintre </span></a>

      </v-card-text>



      <v-divider></v-divider>
      <v-card-text id="contact" class="white--text" >
        {{ new Date().getFullYear() }} — <strong>VeillasInc </strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
name: "Footer",

  computed: {

    options () {
      return {
        duration: 4000,
      }
    },


  }
}
</script>

<style scoped>

</style>