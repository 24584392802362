<template>
  <v-container>

    <Activity
        :imgsThis1="imgsThis1"
        :imgsThis2="imgsThis2"
        :images="images"
        text="Réalisation d’une œuvre avec une technique à base d’œuf  la TEMPERA."
    />

  </v-container>
</template>

<script>
import Activity from "@/components/Activity";

export default {
  name: "Activity2016DiscoveringClassLanslebourg",
  data() {
    return {
      imgsThis1: {
        url: require("@/assets/activities/2016/Lanslebourg, Ecole des Déserts/1.jpg"),
        lazy_url: require("@/assets/activities/2016/Lanslebourg, Ecole des Déserts/L1.jpg"),
        name: "",
      },
      imgsThis2: {
        url: require("@/assets/activities/2016/Lanslebourg, Ecole des Déserts/4.jpg"),
        lazy_url: require("@/assets/activities/2016/Lanslebourg, Ecole des Déserts/L4.jpg"),
        name: "",
      },
      images: [
        {
          url: require("@/assets/activities/2016/Lanslebourg, Ecole des Déserts/3.jpg"),
          lazy_url: require("@/assets/activities/2016/Lanslebourg, Ecole des Déserts/L3.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2016/Lanslebourg, Ecole des Déserts/2.jpg"),
          lazy_url: require("@/assets/activities/2016/Lanslebourg, Ecole des Déserts/L2.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2016/Lanslebourg, Ecole des Déserts/6.jpg"),
          lazy_url: require("@/assets/activities/2016/Lanslebourg, Ecole des Déserts/L6.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2016/Lanslebourg, Ecole des Déserts/5.jpg"),
          lazy_url: require("@/assets/activities/2016/Lanslebourg, Ecole des Déserts/L5.jpg"),
          name: "",
        },

      ]
    }
  },
  components: {
    Activity,
  },

  mounted() {
    this.$vuetify.goTo(0);
  },
}
</script>

<style scoped>

</style>