<template>
  <v-container>

    <Activity
        :imgsThis1="imgsThis1"
        :imgsThis2="imgsThis2"
        :images="images"
        text="Une pandémie n’arrête pas les professeurs des Ecoles qui n’hésitent pas à monter des projets artistiques !"
    />

  </v-container>
</template>

<script>
import Activity from "@/components/Activity";

export default {
  name: "Activity2021VoglansStage",
  data() {
    return {
      imgsThis1: {
        url: require("@/assets/activities//2021/2021VoglansStage/2.png"),
        lazy_url: require("@/assets/activities/2021/2021VoglansStageL/2.png"),
        name: "",
      },
      imgsThis2: {
        url: require("@/assets/activities//2021/2021VoglansStage/4.png"),
        lazy_url: require("@/assets/activities/2021/2021VoglansStageL/4.png"),
        name: "",
      },
      images: [
        {
          url: require("@/assets/activities//2021/2021VoglansStage/3.png"),
          lazy_url: require("@/assets/activities/2021/2021VoglansStageL/3.png"),
          name: "",
        },
        {
          url: require("@/assets/activities//2021/2021VoglansStage/1.png"),
          lazy_url: require("@/assets/activities/2021/2021VoglansStageL/1.png"),
          name: "",
        },
        {
          url: require("@/assets/activities//2021/2021VoglansStage/6.png"),
          lazy_url: require("@/assets/activities/2021/2021VoglansStageL/6.png"),
          name: "",
        },
        {
          url: require("@/assets/activities//2021/2021VoglansStage/5.png"),
          lazy_url: require("@/assets/activities/2021/2021VoglansStageL/5.png"),
          name: "",
        }
      ]
    }
  },
  components: {
    Activity,
  },

  mounted() {
    this.$vuetify.goTo(0);
  },
}
</script>

<style scoped>

</style>