import Vue from 'vue'
import App from './App.vue'
import Router from '@/router/router'
import vuetify from './plugins/vuetify';

import store from '@/store/index'


Vue.config.productionTip = false


new Vue({
  store:store,
  router: Router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
