<template>
  <v-container>

    <Activity
        :imgsThis1="imgsThis1"
        :imgsThis2="imgsThis2"
        :images="images"
        text="Depuis 2015, je travaille en collaboration avec l’Association de Challes Culture Loisirs.
Je donne des cours de dessin peinture à des adultes débutants à confirmés.
En 2016, je mets en place dans cette association des ateliers dessin peinture pour enfants. J’accompagne les jeunes dans leurs projets d’études et de créations.
Dans chaque groupe enfants et adultes, la pluralité des personnalités et les différences entre chacun font, en partie, la richesse des échanges artistiques !
"
    />

  </v-container>
</template>

<script>
import Activity from "@/components/Activity";

export default {
  name: "Activity2018Students",
  data() {
    return {
      imgsThis1: {
        url: require("@/assets/activities/2018/photos élèves Challes/01.jpg"),
        lazy_url: require("@/assets/activities/2018/photos élèves Challes/L01.jpg"),
        name: "",
      },
      imgsThis2: {
        url: require("@/assets/activities/2018/photos élèves Challes/05.jpg"),
        lazy_url: require("@/assets/activities/2018/photos élèves Challes/L05.jpg"),
        name: "",
      },
      images: [
        {
          url: require("@/assets/activities/2018/photos élèves Challes/02.jpg"),
          lazy_url: require("@/assets/activities/2018/photos élèves Challes/L02.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2018/photos élèves Challes/03.jpg"),
          lazy_url: require("@/assets/activities/2018/photos élèves Challes/L03.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2018/photos élèves Challes/04.jpg"),
          lazy_url: require("@/assets/activities/2018/photos élèves Challes/L04.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2018/photos élèves Challes/06.jpg"),
          lazy_url: require("@/assets/activities/2018/photos élèves Challes/L06.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2018/photos élèves Challes/07.jpg"),
          lazy_url: require("@/assets/activities/2018/photos élèves Challes/L07.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2018/photos élèves Challes/08.jpg"),
          lazy_url: require("@/assets/activities/2018/photos élèves Challes/L08.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2018/photos élèves Challes/09.jpg"),
          lazy_url: require("@/assets/activities/2018/photos élèves Challes/L09.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2018/photos élèves Challes/10.jpg"),
          lazy_url: require("@/assets/activities/2018/photos élèves Challes/L10.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2018/photos élèves Challes/11.jpg"),
          lazy_url: require("@/assets/activities/2018/photos élèves Challes/L11.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2018/photos élèves Challes/12.jpg"),
          lazy_url: require("@/assets/activities/2018/photos élèves Challes/L12.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2018/photos élèves Challes/13.jpg"),
          lazy_url: require("@/assets/activities/2018/photos élèves Challes/L13.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2018/photos élèves Challes/14.jpg"),
          lazy_url: require("@/assets/activities/2018/photos élèves Challes/L14.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2018/photos élèves Challes/15.jpg"),
          lazy_url: require("@/assets/activities/2018/photos élèves Challes/L15.jpg"),
          name: "",
        },

      ]
    }
  },
  components: {
    Activity,
  },

  mounted() {
    this.$vuetify.goTo(0);
  },
}
</script>

<style scoped>

</style>