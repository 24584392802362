<template>
    <v-main class="pt-16" >

      <Reception_PC v-if="this.$vuetify.breakpoint.height/this.$vuetify.breakpoint.width<1"/>
      <Reception_Mobile v-else/>



      <v-row
          v-if="this.$vuetify.breakpoint.height/this.$vuetify.breakpoint.width<1"
      >
        <v-col
            class="mx-auto mt-15"
            cols="3"
            v-for="image in images"
            :key="image.lazy_url"
        >
          <Modal :image="image"/>
        </v-col>
      </v-row>
      <v-row
          v-else
          style="margin: 0;"
      >
        <v-col
            class="mx-auto mt-15"
            cols="5"
            v-for="image in images"
            :key="image.lazy_url"
        >
          <Modal :image="image"/>
        </v-col>
      </v-row>
    </v-main>
</template>

<script>
import Reception_PC from "@/components/Reception_PC";
import Reception_Mobile from "@/components/Reception_Mobile";
import Modal from "@/components/Modal";

export default {
  name: "Reception",
  components: {
    Reception_PC,
    Reception_Mobile,
    Modal,
  },
  data() {
    return {
      images: [
        { url: "/img/book3/articles de press/2021.jpg", lazy_url: "/img/book3/articles de press/L2021.jpg", name: "2021 Cours Challes-les-Eaux" },
        { url: "/img/book3/articles de press/2019.jpg", lazy_url: "/img/book3/articles de press/L2019.jpg", name: "2019 Cours Challes-les-Eaux" },
        { url: "/img/book3/articles de press/2019 1.jpg", lazy_url: "/img/book3/articles de press/L2019 1.jpg", name: "2019 Exposition Challes-les-Eaux" },
        { url: "/img/book3/articles de press/2019 2.jpg", lazy_url: "/img/book3/articles de press/L2019 2.jpg", name: "2019 Exposition Challes-les-Eaux" },
        { url: "/img/book3/articles de press/2018.jpg", lazy_url: "/img/book3/articles de press/L2018.jpg", name: "2018 Exposition Challes-les-Eaux" },
        { url: "/img/book3/articles de press/2018 1.jpg", lazy_url: "/img/book3/articles de press/L2018 1.jpg", name: "2018 Exposition Challes-les-Eaux" },
        { url: "/img/book3/articles de press/2018 2.jpg", lazy_url: "/img/book3/articles de press/L2018 2.jpg", name: "2018 Exposition Challes-les-Eaux" },
        { url: "/img/book3/articles de press/2017.jpg", lazy_url: "/img/book3/articles de press/L2017.jpg", name: "2017 Exposition Challes-les-Eaux" },
        { url: "/img/book3/articles de press/2017 1.jpg", lazy_url: "/img/book3/articles de press/L2017 1.jpg", name: "2017 Exposition Challes-les-Eaux" },
        { url: "/img/book3/articles de press/2016.jpg", lazy_url: "/img/book3/articles de press/L2016.jpg", name: "2016 Cours Novalaise" },
        { url: "/img/book3/articles de press/2016 1.jpg", lazy_url: "/img/book3/articles de press/L2016 1.jpg", name: "2016 Cours Challes-les-Eaux" },
        { url: "/img/book3/articles de press/2015.jpg", lazy_url: "/img/book3/articles de press/L2015.jpg", name: "2015" },
        { url: "/img/book3/articles de press/2014.jpg", lazy_url: "/img/book3/articles de press/L2014.jpg", name: "2014" },
        { url: "/img/book3/articles de press/2014 1.jpg", lazy_url: "/img/book3/articles de press/L2014 1.jpg", name: "2014 Exposition Cristal" },
        { url: "/img/book3/articles de press/2014 2.jpg", lazy_url: "/img/book3/articles de press/L2014 2.jpg", name: "2014 Exposition Vernoux-en-Vivarais" },
        { url: "/img/book3/articles de press/2014 3.jpg", lazy_url: "/img/book3/articles de press/L2014 3.jpg", name: "2014 Exposition Vernoux-en-Vivarais" },
        { url: "/img/book3/articles de press/2013.jpg", lazy_url: "/img/book3/articles de press/L2013.jpg", name: "2013 Exposition Vernoux-en-Vivarais" },
        { url: "/img/book3/articles de press/2013 1.jpg", lazy_url: "/img/book3/articles de press/L2013 1.jpg", name: "2013 École St jean XXIII" },
        { url: "/img/book3/articles de press/2013 2.jpg", lazy_url: "/img/book3/articles de press/L2013 2.jpg", name: "2013 École St jean XXIII" },
        { url: "/img/book3/articles de press/2013 3.jpg", lazy_url: "/img/book3/articles de press/L2013 3.jpg", name: "2013 École St jean XXIII" },
        { url: "/img/book3/articles de press/2012.jpg", lazy_url: "/img/book3/articles de press/L2012.jpg", name: "2012 Exposition Chapelle Vaugelas" },
        { url: "/img/book3/articles de press/2012 1.jpg", lazy_url: "/img/book3/articles de press/L2012 1.jpg", name: "2012 Exposition Vernoux-en-Vivarais" },
        { url: "/img/book3/articles de press/2012 2.jpg", lazy_url: "/img/book3/articles de press/L2012 2.jpg", name: "2012 Exposition Vernoux-en-Vivarais" },
        { url: "/img/book3/articles de press/2012 3.jpg", lazy_url: "/img/book3/articles de press/L2012 3.jpg", name: "2012 Exposition Chapelle Vaugelas" },
        { url: "/img/book3/articles de press/2011.jpg", lazy_url: "/img/book3/articles de press/L2011.jpg", name: "2011 Exposition Chapelle Vaugelas" },
      ]
    }
  },
  mounted() {
    this.$vuetify.goTo(0);
  },
}
</script>

<style scoped>

</style>