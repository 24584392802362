<template>
  <v-app>
    <NavBar/>
      <v-main>
        <router-view/>
      </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Router from '@/router/router';
import Footer from "@/components/Footer";
import NavBar from "@/components/NavBar";


export default {
  name: 'App',

  components:{
    Footer,
    NavBar,
  },

  data: () => ({
      Router,
    }),
   mounted(){

  }
};
</script>

<style>

.body {
  padding: 0;
  margin: 0;
  background-color: #F0EEE0;
}
</style>