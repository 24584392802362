<template>

<!--  left version  -->

  <v-timeline-item
      v-if="rightorleft"
      :color="color"
      fill-dot
      left
      small
  >

    <!--    PC Version    -->
    <v-card>
      <v-card-title :style="'background-color: '+color" class="hidden-sm-and-down justify-end">
        <h2 class="display-1 mr-2 white--text font-weight-light hyphen">
          {{ title }}

        </h2>
        <v-icon
            dark
            size="42"
        >
          {{ mdiIcon }}
        </v-icon>
      </v-card-title>


      <!--    Mobile Version    -->
      <v-card-title :style="'background-color: '+color" class="hidden-md-and-up justify-space-around">
        <h4  class="display-0 mr-2 white--text font-weight-light">
          {{ title }}
          <v-icon
              dark
              size="42"
          >
            {{ mdiIcon }}
          </v-icon>
        </h4>
      </v-card-title>


      <!--    PC Version    -->
      <v-container>
        <v-row class="mx-auto" align="center">
          <v-col
              class="hidden-sm-and-down text-right"
              md="6"
              cols="6"
          >
            <v-row v-for="image in images" :key="image.lazy_url">
              <v-col cols="12" >
                <v-img
                    class="image--shadow"
                    contain
                    :max-width="maxWidth"
                    :src="image.lazy_url"
                ></v-img>
              </v-col>
            </v-row>

          </v-col>


          <v-col
              md="6"
              align-text="center"
              class="mx-auto text-right"
          >
                  <span>
                    <i>{{ text }}</i> <br/><hr/><br/>
                    <b>{{ lieu }}</b> <br/><br/>
                    <v-btn :color="color" class="white--text" large :to="link">
                      Les photos ici !
                    </v-btn>

                  </span>
          </v-col>
        </v-row>

        <!--    Mobile Version    -->
        <v-row class="mx-auto">
          <v-col
              class="hidden-md-and-up text-right"
              md="6"
              cols="12"
          >
            <v-row class="mx-auto align-center">
              <v-col cols="6" v-for="image in images" :key="image.lazy_url">
                <v-img
                    class="image--shadow"
                    :max-width="maxWidth"
                    :src="image.lazy_url"
                ></v-img>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-timeline-item>


<!--  Right version  -->

  <v-timeline-item
      v-else
      :color="color"
      fill-dot
      right
  >

    <!--    PC Version    -->
    <v-card>
      <v-card-title :style="'background-color: '+color" class="hidden-sm-and-down">
        <v-icon
            dark
            size="42"
            class="mr-2"
        >
          {{ mdiIcon }}
        </v-icon>
        <h2 class="display-1 mr-2 white--text font-weight-light">
          {{ title }}

        </h2>
      </v-card-title>

      <!--    Mobile Version    -->
      <v-card-title :style="'background-color: '+color" class="hidden-md-and-up isColored justify-space-around">

        <h4 class="display-0 mr-2 white--text font-weight-light">
          <v-icon
              dark
              size="42"
          >
            {{ mdiIcon }}
          </v-icon>
          {{ title }}
        </h4>

      </v-card-title>


      <!--    PC Version    -->
      <v-container>
        <v-row class="mx-auto" align="center">

          <v-col
              md="6"
              align-text="center"
              class="mx-auto text-left"
          >
                  <span>
                    <i>{{ text }}</i> <br/><hr/><br/>
                    <b>{{ lieu }}</b> <br/><br/>
                    <v-btn :color="color" class="white--text"  large  :to="link">
                      Les photos ici !
                    </v-btn>

                  </span>
          </v-col>
          <v-col
              class="hidden-sm-and-down text-left"
              md="6"
              cols="6"
          >
            <v-row v-for="image in images" :key="image.lazy_url">
              <v-col cols="12">
                <v-img
                    class="image--shadow"
                    contain
                    :max-width="maxWidth"
                    :src="image.lazy_url"
                ></v-img>
              </v-col>
            </v-row>

          </v-col>
        </v-row>

        <!--    Mobile Version    -->
        <v-row class="mx-auto">
          <v-col
              class="hidden-md-and-up text-right"
              md="6"
              cols="12"
          >
            <v-row class="mx-auto align-center">
              <v-col cols="6" v-for="image in images" :key="image.lazy_url">
                <v-img
                    class="image--shadow"
                    :max-width="maxWidth"
                    :src="image.lazy_url"
                ></v-img>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-timeline-item>


</template>

<script>
export default {
  name: "TimeLinePerso",
  props: [
    'number',
    'color',
    'title',
    'text',
    'link',
    'images',
    'mdiIcon',
    'maxWidth',
    'lieu',
    'rightorleft',
  ]

}
</script>


<style scoped>

</style>