<template>
  <div class="text-center" >
      <v-img
          class="image--shadow transition-swing mx-auto"
          :src="image.lazy_url"
          max-width="300"
          @click="dialog = true"
      >
      </v-img>
    <v-container v-if="image.name != ''">
      <v-card v-if="this.$vuetify.breakpoint.width > 1000" class="image--shadow mt-2 mx-auto" color="white" max-width="300"> <v-card-text> {{image.name}} </v-card-text> </v-card>
      <p v-else class="mt-2" style="font-family:Serif; ">{{image.name}}</p>
    </v-container>

    <v-dialog
        v-model="dialog"
        :height="0.90*this.$vuetify.breakpoint.height"
        :width="0.90*this.$vuetify.breakpoint.width"
    >
      <v-img
          :src="image.url"
          :lazy-src="image.lazy_url"
          @click="dialog = false"
          :height="0.90*this.$vuetify.breakpoint.height"
          :width="0.90*this.$vuetify.breakpoint.width"
          contain
      >
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>

      </v-img>

    </v-dialog>


  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      dialog: false,
    }
  },
  props: [
    'image'
  ]
}
</script>

<style scoped>


.v-dialog {
  box-shadow: none !important;
}

.v-image.image--shadow {
  box-shadow: 0 6px 6px -3px rgba(0,0,0,.2),0 10px 14px 1px rgba(0,0,0,.14),0 4px 18px 3px rgba(0,0,0,.12)!important;
}

.v-image.image--shadow:hover{
  box-shadow: 0 11px 15px -7px rgba(0,0,0,.2),0 24px 38px 3px rgba(0,0,0,.14),0 9px 46px 8px rgba(0,0,0,.12)!important;
  cursor: pointer;
  margin-top: -15px;
  margin-bottom: 15px;
}

.v-application .transition-swing {
  transition: .3s cubic-bezier(.25,.8,.5,1)!important;
  transition-property: all !important;
  transition-duration: 0.3s !important;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1) !important;
  transition-delay: 0s !important;
}

</style>