<template>
  <v-container>
    <template>
      <v-timeline :dense="$vuetify.breakpoint.smAndDown">



        <!--    ------------------- Stage Gurraz 2021 ------------------- -->

        <TimeLinePerso
            number="2"
            color="#A7194B"
            title="Stage la Gurraz 2021"
            mdiIcon="mdi-pen"
            text="Un stage avec des maternelles et primaires."
            lieu="Gurraz, Savoie"
            link="/Activity2021GurrazStage"
            :images="images7"
            :rightorleft="right"
        />

        <!--    ------------------- Stage Voglans 2021 ------------------- -->

        <TimeLinePerso
            number="2"
            color="#BA68C8"
            title="Stage Voglans 2021"
            mdiIcon="mdi-palette"
            text="4 jours de découvertes et de créations !"
            lieu="Voglans"
            link="/2021VoglansStage"
            :images="images1"
            :rightorleft="left"
        />

        <!--        -------------- Stage été 2020 -------------- -->

        <TimeLinePerso
            number="2"
            color="#FFCA28"
            title="Stage été 2020"
            mdiIcon="mdi-brush"
            text="Déconfinez-vous la tête et les mains dans un cadre agréable !"
            lieu="La Maison Rouge"
            link="/2020JulyStage"
            :images="images2"
            :rightorleft="right"
        />


        <!--    ------------------- Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019 ------------------- -->

        <TimeLinePerso
            number="2"
            color="#F7004A"
            title="Lanslebourg 2019"
            mdiIcon="mdi-brush"
            text="Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2)"
            lieu="Lanslebourg"
            link="/Activity2019DiscoveringClass"
            :images="images3"
            :rightorleft="left"
        />

        <!--    ------------------- Élèves en action Challes 2018 ------------------- -->

        <TimeLinePerso
            number="2"
            color="#007FFF"
            title="Cours 2018"
            mdiIcon="mdi-brush"
            text="Élèves en action !"
            lieu="Challes-Les-Eaux"
            link="/Activity2018Students"
            :images="images4"
            :rightorleft="right"
        />


        <!--    ------------------- Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017 ------------------- -->

        <TimeLinePerso
            number="2"
            color="#83002B"
            title="Arèches Beaufort 2017"
            mdiIcon="mdi-brush"
            text="Classe de découverte, Ecole Jean Jaures"
            lieu="Arèches Beaufort"
            link="/Activity2017DiscoveringClassBeaufort"
            :images="images5"
            :rightorleft="left"
        />


        <!--    ------------------- Classe de découverte Arèches Beaufort, Ecole Jean Jaures 2017 ------------------- -->

        <TimeLinePerso
            number="2"
            color="#00FFAD"
            title="Lanslebourg 2016"
            mdiIcon="mdi-brush"
            text="Classe de découverte, Ecole des Déserts"
            lieu="Lanslebourg"
            link="/Activity2016DiscoveringClassLanslebourg"
            :images="images6"
            :rightorleft="right"
        />


      </v-timeline>
    </template>
  </v-container>
</template>

<script>

import TimeLinePerso from "@/components/TimeLinePerso";

export default {
  name: 'HelloWorld',
  components: {
    TimeLinePerso,
  },

  data() {
    return {

      right: false,
      left: true,

      images1: [
        {
          url: "/img/activities/2021/2021VoglansStage/1.png",
          lazy_url: "/img/activities/2021/2021VoglansStageL/1.png"
        },
        {
          url: "/img/activities/2021/2021VoglansStage/6.png",
          lazy_url: "/img/activities/2021/2021VoglansStageL/6.png"
        },
      ],

      images2: [
        {
          url: "/img/activities/2020/2020JulyStage/10.jpg",
          lazy_url: "/img/activities/2020/2020JulyStageL/10.jpg"
        },
        {
          url: "/img/activities/2020/2020JulyStage/6.jpg",
          lazy_url: "/img/activities/2020/2020JulyStageL/6.jpg"
        },
      ],

      images3: [
        {
          url: "/img/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/05.jpg",
          lazy_url: "/img/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L05.jpg"
        },
        {
          url: "/img/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/10.jpg",
          lazy_url: "/img/activities/2019/Classe de découverte Lanslebourg Ecole la Rochette (CE1.CE2) et l'Ecole de Villard Sallet(CM1.CM2) juin 2019/FormatedNames/L10.jpg"
        },
      ],

      images4: [
        {
          url: "/img/activities/2018/photos élèves Challes/01.jpg",
          lazy_url: "/img/activities/2018/photos élèves Challes/L01.jpg",
        },
        {
          url: "/img/activities/2018/photos élèves Challes/05.jpg",
          lazy_url: "/img/activities/2018/photos élèves Challes/L05.jpg",
        },
      ],

      images5: [
        {
          url: "/img/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/3.jpg",
          lazy_url: "/img/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/L3.jpg",
        },
        {
          url: "/img/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/6.jpg",
          lazy_url: "/img/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/L6.jpg",
        },
      ],

      images6: [
        {
          url: "/img/activities/2016/Lanslebourg, Ecole des Déserts/1.jpg",
          lazy_url: "/img/activities/2016/Lanslebourg, Ecole des Déserts/L1.jpg",
        },
        {
          url: "/img/activities/2016/Lanslebourg, Ecole des Déserts/6.jpg",
          lazy_url: "/img/activities/2016/Lanslebourg, Ecole des Déserts/L6.jpg",
        },
      ],
      images7:[
        {
          url: require("@/assets/activities/2021/2021GurrazStage/04.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 04.png"),
        },
        {
          url: require("@/assets/activities/2021/2021GurrazStage/02.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 02.png"),
        }
      ]

    }
  },


  mounted() {
    this.$vuetify.goTo(0);
  },
}
</script>


<style>
.v-dialog {
  box-shadow: none !important;
}

.v-image.image--shadow {

  box-shadow: 0px 4px 4px -5px rgba(0, 0, 0, 0.2), 0px 10px 12px 2px rgba(0, 0, 0, 0.14), 0px 3px 14px 3px rgba(0, 0, 0, 0.12);

}

</style>