import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        mongoose:null
    },
    getters:{
        getMongoose(state){
            return state.mongoose;
        },
    },
    mutations:{
        setMongoose(state, mongoose){
            state.mongoose = mongoose;
        }
    },
});