<template>
  <v-container>

    <Activity
        :imgsThis1="imgsThis1"
        :imgsThis2="imgsThis2"
        :images="images"
        text="Les enfants ont pu s’exprimer devant le splendide panorama du Beaufortain !
              Les pigments sont à l’honneur après une étude du paysage et réalisation sur un support bois."
    />

  </v-container>
</template>

<script>
import Activity from "@/components/Activity";

export default {
  name: "Activity2017DiscoveringClassBeaufort",
  data() {
    return {
      imgsThis1: {
        url: require("@/assets/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/3.jpg"),
        lazy_url: require("@/assets/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/L3.jpg"),
        name: "",
      },
      imgsThis2: {
        url: require("@/assets/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/6.jpg"),
        lazy_url: require("@/assets/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/L6.jpg"),
        name: "",
      },
      images: [
        {
          url: require("@/assets/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/1.jpg"),
          lazy_url: require("@/assets/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/L1.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/2.jpg"),
          lazy_url: require("@/assets/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/L2.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/4.jpg"),
          lazy_url: require("@/assets/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/L4.jpg"),
          name: "",
        },
        {
          url: require("@/assets/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/5.jpg"),
          lazy_url: require("@/assets/activities/2017/Classe de découverte Arèche Beaufort, Ecole Jean Jaures 2017/L5.jpg"),
          name: "",
        },

      ]
    }
  },
  components: {
    Activity,
  },

  mounted() {
    this.$vuetify.goTo(0);
  },
}
</script>

<style scoped>

</style>