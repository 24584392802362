<template>
  <v-container>

    <Activity
        :imgsThis1="imgsThis1"
        :imgsThis2="imgsThis2"
        :images="images"
        text="Intervention dans une petite école en classe unique de 19 élèves de maternelles et primaires."
    />

  </v-container>
</template>

<script>
import Activity from "@/components/Activity";

export default {
  name: "Activity2021GurrazStage",
  data() {
    return {
      imgsThis1: { 
        url: require("@/assets/activities/2021/2021GurrazStage/04.png"),
        lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 04.png"),
        name: "",
      },
      imgsThis2: {
        url: require("@/assets/activities/2021/2021GurrazStage/02.png"),
        lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 02.png"),
        name: "",
      },
      images: [
        {
          url: require("@/assets/activities/2021/2021GurrazStage/01.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 01.png"),
          name: "",
        },
        {
          url: require("@/assets/activities/2021/2021GurrazStage/03.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 03.png"),
          name: "",
        },
        {
          url: require("@/assets/activities/2021/2021GurrazStage/05.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 05.png"),
          name: "",
        },
        {
          url: require("@/assets/activities/2021/2021GurrazStage/06.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 06.png"),
          name: "",
        },
        {
          url: require("@/assets/activities/2021/2021GurrazStage/07.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 07.png"),
          name: "",
        },
        {
          url: require("@/assets/activities/2021/2021GurrazStage/08.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 08.png"),
          name: "",
        },
        {
          url: require("@/assets/activities/2021/2021GurrazStage/09.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 09.png"),
          name: "",
        },
        {
          url: require("@/assets/activities/2021/2021GurrazStage/10.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 10.png"),
          name: "",
        },
        {
          url: require("@/assets/activities/2021/2021GurrazStage/11.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 11.png"),
          name: "",
        },
        {
          url: require("@/assets/activities/2021/2021GurrazStage/12.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 12.png"),
          name: "",
        },
        {
          url: require("@/assets/activities/2021/2021GurrazStage/13.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 13.png"),
          name: "",
        },
        {
          url: require("@/assets/activities/2021/2021GurrazStage/14.png"),
          lazy_url: require("@/assets/activities/2021/2021GurrazStage/L 14.png"),
          name: "",
        },

      ]
    }
  },
  components: {
    Activity,
  },

  mounted() {
    this.$vuetify.goTo(0);
  },
}
</script>

<style scoped>

</style>