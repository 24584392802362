<template>
  <v-container>
    <v-row class="ml-8 mt-5 mb-n10" align="center">
      <v-col cols="6" align="right" >
        <v-card
            class=""
            outlined
            shaped
            width="500"

        >
          <v-card-title>Artiste peintre autodidacte </v-card-title>
          <v-card-text>
            <p>
              Née à Chambéry en 1971, j’ai grandi à St Alban Leysse avec mes parents et mes 7 frères et sœurs.
              Mes copains, copines remarquent mes goûts pour la peinture et décident de m’offrir du matériel lors d’une de mes convalescences.<br/>
              Ensuite je me forme pendant une dizaine d’années en suivant divers cours, puis en approfondissant diverses techniques, particulièrement celle de la peinture à l’huile. Ma curiosité naturelle et Paul Cézanne, Fabien Novarino et Salvator Dali,
              mes mentors, m’ont permis d’intégrer diverses approches que je retransmets, dans mes créations avec la présence d’eau en mouvement, mais également à mes élèves en les accompagnant dans leurs projets personnels.
            </p>

            <p>
              En 2013, je crée ma micro société inscrite à la Maison des Artistes, et je gère depuis mon entreprise à la fois en montant des projets artistiques variés pour des associations de quartier ainsi que pour l’Education Nationale, en réalisant des commandes, en organisant des expositions personnelles, en élaborant des parcours pédagogiques, puis plus récemment en donnant des cours particuliers.
            </p>

            <!--            Muriel Veillas, une artiste talentueuse, bourrée de qualité et qui plus est splendide. Muriel Veillas est-->
<!--            une personne au bonheur débordant, si bien qu'elle le partage ! Elle a ainsi lancé sa carrière de-->
<!--            professeure de peinture après de multiples expositions et a même pu réaliser un stage dans un cadre-->
<!--            magnifique à la Maison Rouge. Essayer un cours avec elle, c'est l'adopter !-->

          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
            class="mt-n16"
            :width="0.25*this.$vuetify.breakpoint.width"
            :height="0.25*this.$vuetify.breakpoint.width"
            elevation="15"
        >
          <v-img
              contain
              src="@/assets/Her.jpg"
          ></v-img>
        </v-card>


        <v-card
            class="mt-8"
            :width="0.25*this.$vuetify.breakpoint.width"
            elevation="15"
        >
          <v-img
              contain
              :max-width="0.30*this.$vuetify.breakpoint.width"
              src="@/assets/LaPeintreEnAction.jpg"
          ></v-img>
        </v-card>

      </v-col>
    </v-row>
<!--    ------------------------    -->
    <v-row class="ml-8 mt-15 mb-15" align="center">

      <v-col cols="6"  align="right">
        <v-card
            class="mt-n15"
            :width="0.25*this.$vuetify.breakpoint.width"
            :height="0.25*this.$vuetify.breakpoint.width"
            elevation="15"
        >
          <v-img
              contain
              src="@/assets/moi à la maternelle.jpg"
          ></v-img>
        </v-card>
      </v-col>

      <v-col cols="6" align="left" class="mt-12">
        <v-card
            class=""
            outlined
            shaped
            width="500"
        >
          <v-card-title>Remerciements, </v-card-title>
          <v-card-text>

            <p>Un grand MERCI à ma famille et mes copains qui croient  en moi chaque jour.</p>
            <p>MERCI aux différentes associations : l’Association des Classes de Découvertes, Challes Culture Loisirs, le Centre Socioculturel A.E.L., aux professeurs des écoles et à tous mes élèves qui me font confiance depuis des années.</p>
            <p>Un MERCI particulier à mon fils Quentin qui a imaginé, créé et réalisé ce site.</p>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-15">
      <v-col
          cols="6">
        <v-spacer></v-spacer>
      </v-col>
      <v-col
          class="mt-15"
          cols="6"
          align="left"
      >
        <v-card
            class="image--shadow mt-16 ml-16"
            max-width="300"
            color="yellow darken-2"> <v-card-title class="text-center white--text justify-center py-6"> <h2>Articles de Presse</h2>  </v-card-title> </v-card>
      </v-col>
    </v-row>


  </v-container>

</template>

<script>
export default {
  name: "Reception_PC"
}
</script>
<style scoped>
.v-card{

  box-shadow: 0px 4px 4px -5px rgba(0, 0, 0, 0.2), 0px 10px 12px 2px rgba(0, 0, 0, 0.14), 0px 3px 14px 3px rgba(0, 0, 0, 0.12);

}



</style>