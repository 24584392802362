<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <v-card elevation="5" class="align-center" color="#7CB342" dark min-width="210" width="210" style="z-index: 100; position: fixed;">
          <v-card-text>Cliquez sur les images <v-icon class="ml-2">mdi-image</v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>



    <v-row
        v-if="this.$vuetify.breakpoint.width > 1000"
        class=" align-center mx-auto"
    >
      <v-col
          class="mx-auto"
          cols="4"
          v-for="image in images1"
          :key="image.lazy_url"
      >
        <Modal :image="image"/>

      </v-col>
      <v-col
          class="mx-auto"
          cols="6"
          v-for="image in images2"
          :key="image.lazy_url"
      >
        <Modal :image="image"/>

      </v-col>
      <v-col
          class="mx-auto"
          cols="3"
          v-for="image in images3"
          :key="image.lazy_url"
      >
        <Modal :image="image"/>

      </v-col>
      <v-col
          class="mx-auto"
          cols="3"
          v-for="image in images4"
          :key="image.lazy_url"
      >
        <Modal :image="image"/>

      </v-col>
      <v-col
          class="mx-auto"
          cols="6"
          v-for="image in images5"
          :key="image.lazy_url"
      >
        <Modal :image="image"/>

      </v-col>
      <v-col
          class="mx-auto"
          cols="4"
          v-for="image in images6"
          :key="image.lazy_url"
      >
        <Modal :image="image"/>

      </v-col>
      <v-col
        class="mx-auto"
        cols="4"
        v-for="image in images7"
        :key="image.lazy_url"
    >
      <Modal :image="image"/>
    </v-col>
      <v-col
          class="mx-auto"
          cols="4"
          v-for="image in images8"
          :key="image.lazy_url"
      >
        <Modal :image="image"/>

      </v-col>
      <v-col
          class="mx-auto"
          cols="2"
          v-for="image in images9"
          :key="image.lazy_url"
      >
        <Modal :image="image"/>

      </v-col>
      <v-col
          class="mx-auto"
          cols="6"
          v-for="image in images10"
          :key="image.lazy_url"
      >
        <Modal :image="image"/>

      </v-col>
      <v-col
          class="mx-auto"
          cols="3"
          v-for="image in images11"
          :key="image.lazy_url"
      >
        <Modal :image="image"/>

      </v-col>

      <v-col
          class="mx-auto"
             cols="12"
      >
        <v-card class="image--shadow mt-2 mx-auto" max-width="350" color="light-blue"> <v-card-title class="text-center white--text justify-center py-6"> <h1> Tableaux vendus</h1>  </v-card-title> </v-card>
      </v-col>
      <v-col
          class="mx-auto"
          cols="3"
          v-for="image in vendus"
          :key="image.lazy_url"
      >
        <Modal :image="image"/>

      </v-col>


    </v-row>
    <v-row
        class="justify-center align-center mx-auto mt-14"
        v-else
    >
      <v-col
          cols="5"
          v-for="image in images"
          :key="image.lazy_url"
      >
        <Modal :image="image"/>

      </v-col>

      <v-col
          class="mx-auto"
          cols="12"
      >
        <v-card class="image--shadow mt-2 mx-auto" max-width="350" color="light-blue"> <v-card-title class="text-center white--text justify-center py-6"> <h2> Tableaux vendus</h2>  </v-card-title> </v-card>
      </v-col>
      <v-col
          class="mx-auto mt-15"
          cols="5"
          v-for="image in vendus"
          :key="image.lazy_url"
      >
        <Modal :image="image"/>

      </v-col>


    </v-row>
  </v-container>
</template>

<script>
import Modal from "../components/Modal";

export default {
  name: 'Book',
  components: {
    Modal
  },
  data() {
    return {
      images:[
        {url: "/img/book3/layerPaintings/Il était une fois...60X30.jpg", lazy_url: "/img/book3/layerPaintings/LIl était une fois...60X30.jpg", name: "Il était une fois... 60X30"},
        {url: "/img/book3/layerPaintings/MOI 65X50.jpg", lazy_url: "/img/book3/layerPaintings/LMOI 65X50.jpg", name: "MOI 65X50"},
        {url: "/img/book3/layerPaintings/PLOUF 65X50 (2).jpg", lazy_url: "/img/book3/layerPaintings/LPLOUF 65X50 (2).jpg", name: "PLOUF 65X50"},
        {url: "/img/book3/layerPaintings/EXPIRATION 50X50.jpg", lazy_url: "/img/book3/layerPaintings/LEXPIRATION 50X50.jpg", name: "EXPIRATION 50X50"},
        {url: "/img/book3/layerPaintings/2.MOJITOS 50X70.jpg", lazy_url: "/img/book3/layerPaintings/L2.MOJITOS 50X70.jpg", name: "MOJITOS 50X70"},
        {url: "/img/book3/layerPaintings/1 Glaçonade de billes 45x55.jpg", lazy_url: "/img/book3/layerPaintings/L1 Glaçonade de billes 45x55.jpg", name: "Glaçonade de billes 45x55"},
        {url: "/img/book3/layerPaintings/L'ARTHROSE 50X70.jpg", lazy_url: "/img/book3/layerPaintings/LL'ARTHROSE 50X70.jpg", name: "L'ARTHROSE 50X70"},
        {url: "/img/book3/layerPaintings/Ballade en bleu 70X70.jpg", lazy_url: "/img/book3/layerPaintings/LBallade en bleu 70X70.jpg", name: "Ballade en bleu 70X70"},
        {url: "/img/book3/layerPaintings/L'ESPOIR EN ROSE 60X60.jpg", lazy_url: "/img/book3/layerPaintings/LL'ESPOIR EN ROSE 60X60.jpg", name: "L'ESPOIR EN ROSE 60X60"},
        {url: "/img/book3/layerPaintings/Puissance de la vie 50X100.jpg", lazy_url: "/img/book3/layerPaintings/LPuissance de la vie 50X100.jpg", name: "Puissance de la vie 50X100"},
        {url: "/img/book3/layerPaintings/FUSION 60X60.jpg", lazy_url: "/img/book3/layerPaintings/LFUSION 60X60.jpg", name: "FUSION 60X60"},
        {url: "/img/book3/layerPaintings/FONDS BLEUS 60X60.jpg", lazy_url: "/img/book3/layerPaintings/LFONDS BLEUS 60X60.jpg", name: "FONDS BLEUS 60X60.jpg"},
        {url: "/img/book3/layerPaintings/Réflexion 50x50.jpg", lazy_url: "/img/book3/layerPaintings/LRéflexion 50x50.jpg", name: "Réflexion 50x50"},
        {url: "/img/book3/layerPaintings/GIRAFON 50X50.jpg", lazy_url: "/img/book3/layerPaintings/LGIRAFON 50X50.jpg", name: "GIRAFON 50X50"},
        {url: "/img/book3/layerPaintings/Intimité cachée 55X70.jpg", lazy_url: "/img/book3/layerPaintings/LIntimité cachée 55X70.jpg", name: "Intimité cachée 55X70"},
        {url: "/img/book3/layerPaintings/EAU FOLLE 50X70.jpg", lazy_url: "/img/book3/layerPaintings/LEAU FOLLE 50X70.jpg", name: "EAU FOLLE 50X70"},
        {url: "/img/book3/layerPaintings/DOUCEUR OMBRE ET LUMIERE 65X53.jpg", lazy_url: "/img/book3/layerPaintings/LDOUCEUR OMBRE ET LUMIERE 65X53.jpg", name: "DOUCEUR OMBRE ET LUMIERE 65X53"},
        {url: "/img/book3/layerPaintings/Dance du pavot 80X80.jpg", lazy_url: "/img/book3/layerPaintings/LDance du pavot 80X80.jpg", name: "Dance du pavot 80X80"},
        {url: "/img/book3/layerPaintings/Les pavots du sommeil 80X80.jpg", lazy_url: "/img/book3/layerPaintings/LLes pavots du sommeil 80X80.jpg", name: "Les pavots du sommeil 80X80"},
        {url: "/img/book3/layerPaintings/L'homme aux yeux d'or 50X50.jpg", lazy_url: "/img/book3/layerPaintings/LL'homme aux yeux d'or 50X50.jpg", name: "L'homme aux yeux d'or 50X50"},
        {url: "/img/book3/layerPaintings/Tristesse d'une abeille  54X72.jpg", lazy_url: "/img/book3/layerPaintings/LTristesse d'une abeille  54X72.jpg", name: "Tristesse d'une abeille 54X72"},
        {url: "/img/book3/layerPaintings/Le Paon 41x22.jpg", lazy_url: "/img/book3/layerPaintings/LLe Paon 41x22.jpg", name: "Le Paon 41x22"},
        {url: "/img/book3/layerPaintings/Echange constructif 40x40.jpg", lazy_url: "/img/book3/layerPaintings/LEchange constructif 40x40.jpg", name: "Echange constructif 40x40"},
        {url: "/img/book3/layerPaintings/Le temple de la méditation 40X40.jpg", lazy_url: "/img/book3/layerPaintings/LLe temple de la méditation 40X40.jpg", name: "Le temple de la méditation 40X40 "},
        {url: "/img/book3/layerPaintings/THE TABLE...80X40.jpg", lazy_url: "/img/book3/layerPaintings/LTHE TABLE...80X40.jpg", name: "THE TABLE... 80X40 "},
        {url: "/img/book3/layerPaintings/Paysage secret 100X50.jpg", lazy_url: "/img/book3/layerPaintings/LPaysage secret 100X50.jpg", name: "Paysage secret 100X50 "},
        {url: "/img/book3/layerPaintings/TOURMENTE 80X40.jpg", lazy_url: "/img/book3/layerPaintings/LTOURMENTE 80X40.jpg", name: "TOURMENTE 80X40 "},
        {url: "/img/book3/layerPaintings/LE TIMBRE 60X40.jpg", lazy_url: "/img/book3/layerPaintings/LLE TIMBRE 60X40.jpg", name: "LE TIMBRE 60X40 "},
        {url: "/img/book3/layerPaintings/DAVID 100X50.jpg", lazy_url: "/img/book3/layerPaintings/LDAVID 100X50.jpg", name: "DAVID 100X50"},
        {url: "/img/book3/layerPaintings/L'HOMME DETENDU 50X60 (2).jpg", lazy_url: "/img/book3/layerPaintings/LL'HOMME DETENDU 50X60 (2).jpg", name: "L'HOMME DETENDU 50X60"},
        {url: "/img/book3/layerPaintings/Auto portrait74X54.jpg", lazy_url: "/img/book3/layerPaintings/LAuto portrait74X54.jpg", name: "Auto portrait 74X54 "},
        {url: "/img/book3/layerPaintings/Evolution 40X40.jpg", lazy_url: "/img/book3/layerPaintings/LEvolution 40X40.jpg", name: "Evolution 40X40"},
        {url: "/img/book3/layerPaintings/IMG_1303.jpg", lazy_url: "/img/book3/layerPaintings/LIMG_1303.jpg", name: ""},
        {url: "/img/book3/layerPaintings/La réalité du quotidien 60X60.jpg", lazy_url: "/img/book3/layerPaintings/LLa réalité du quotidien 60X60.jpg", name: "La réalité du quotidien 60X60 "},
        {url: "/img/book3/layerPaintings/MES PETITS BONHEURS 74X35.jpg", lazy_url: "/img/book3/layerPaintings/LMES PETITS BONHEURS 74X35.jpg", name: "MES PETITS BONHEURS 74X35"},
        {url: "/img/book3/layerPaintings/IMG_1285.jpg", lazy_url: "/img/book3/layerPaintings/LIMG_1285.jpg", name: ""},

      ],
      vendus : [
        {url: "/img/book3/tableaux vendu/1.jpg", lazy_url: "/img/book3/tableaux vendu/L1.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/2.jpg", lazy_url: "/img/book3/tableaux vendu/L2.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/3.jpg", lazy_url: "/img/book3/tableaux vendu/L3.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/4.jpg", lazy_url: "/img/book3/tableaux vendu/L4.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/5.jpg", lazy_url: "/img/book3/tableaux vendu/L5.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/6.jpg", lazy_url: "/img/book3/tableaux vendu/L6.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/7.jpg", lazy_url: "/img/book3/tableaux vendu/L7.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/8.jpg", lazy_url: "/img/book3/tableaux vendu/L8.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/9.jpg", lazy_url: "/img/book3/tableaux vendu/L9.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/10.jpg", lazy_url: "/img/book3/tableaux vendu/L10.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/11.jpg", lazy_url: "/img/book3/tableaux vendu/L11.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/12.jpg", lazy_url: "/img/book3/tableaux vendu/L12.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/13.jpg", lazy_url: "/img/book3/tableaux vendu/L13.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/14.jpg", lazy_url: "/img/book3/tableaux vendu/L14.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/15.jpg", lazy_url: "/img/book3/tableaux vendu/L15.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/16.jpg", lazy_url: "/img/book3/tableaux vendu/L16.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/17.jpg", lazy_url: "/img/book3/tableaux vendu/L17.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/18.jpg", lazy_url: "/img/book3/tableaux vendu/L18.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/19.jpg", lazy_url: "/img/book3/tableaux vendu/L19.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/20.jpg", lazy_url: "/img/book3/tableaux vendu/L20.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/21.jpg", lazy_url: "/img/book3/tableaux vendu/L21.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/22.jpg", lazy_url: "/img/book3/tableaux vendu/L22.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/23.jpg", lazy_url: "/img/book3/tableaux vendu/L23.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/24.jpg", lazy_url: "/img/book3/tableaux vendu/L24.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/25.jpg", lazy_url: "/img/book3/tableaux vendu/L25.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/26.jpg", lazy_url: "/img/book3/tableaux vendu/L26.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/27.jpg", lazy_url: "/img/book3/tableaux vendu/L27.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/28.jpg", lazy_url: "/img/book3/tableaux vendu/L28.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/29.jpg", lazy_url: "/img/book3/tableaux vendu/L29.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/30.jpg", lazy_url: "/img/book3/tableaux vendu/L30.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/31.jpg", lazy_url: "/img/book3/tableaux vendu/L31.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/32.jpg", lazy_url: "/img/book3/tableaux vendu/L32.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/33.jpg", lazy_url: "/img/book3/tableaux vendu/L33.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/34.jpg", lazy_url: "/img/book3/tableaux vendu/L34.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/35.jpg", lazy_url: "/img/book3/tableaux vendu/L35.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/36.jpg", lazy_url: "/img/book3/tableaux vendu/L36.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/37.jpg", lazy_url: "/img/book3/tableaux vendu/L37.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/38.jpg", lazy_url: "/img/book3/tableaux vendu/L38.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/39.jpg", lazy_url: "/img/book3/tableaux vendu/L39.jpg", name: ""},
        {url: "/img/book3/tableaux vendu/40.jpg", lazy_url: "/img/book3/tableaux vendu/L40.jpg", name: ""},

      ],

      images1: [
        {url: "/img/book3/layerPaintings/Il était une fois...60X30.jpg", lazy_url: "/img/book3/layerPaintings/LIl était une fois...60X30.jpg", name: "Il était une fois... 60X30"},
        {url: "/img/book3/layerPaintings/MOI 65X50.jpg", lazy_url: "/img/book3/layerPaintings/LMOI 65X50.jpg", name: "MOI 65X50"},
        {url: "/img/book3/layerPaintings/IMG_1285.jpg", lazy_url: "/img/book3/layerPaintings/LIMG_1285.jpg", name: ""},
      ],
      images2: [
        {url: "/img/book3/layerPaintings/PLOUF 65X50 (2).jpg", lazy_url: "/img/book3/layerPaintings/LPLOUF 65X50 (2).jpg", name: "PLOUF 65X50"},
        {url: "/img/book3/layerPaintings/EXPIRATION 50X50.jpg", lazy_url: "/img/book3/layerPaintings/LEXPIRATION 50X50.jpg", name: "EXPIRATION 50X50"},
      ],
      images3: [
        {url: "/img/book3/layerPaintings/2.MOJITOS 50X70.jpg", lazy_url: "/img/book3/layerPaintings/L2.MOJITOS 50X70.jpg", name: "MOJITOS 50X70"},
        {url: "/img/book3/layerPaintings/1 Glaçonade de billes 45x55.jpg", lazy_url: "/img/book3/layerPaintings/L1 Glaçonade de billes 45x55.jpg", name: "Glaçonade de billes 45x55"},
        {url: "/img/book3/layerPaintings/L'ARTHROSE 50X70.jpg", lazy_url: "/img/book3/layerPaintings/LL'ARTHROSE 50X70.jpg", name: "L'ARTHROSE 50X70"},
        {url: "/img/book3/layerPaintings/Ballade en bleu 70X70.jpg", lazy_url: "/img/book3/layerPaintings/LBallade en bleu 70X70.jpg", name: "Ballade en bleu 70X70"},
      ],
      images4: [
        {url: "/img/book3/layerPaintings/L'ESPOIR EN ROSE 60X60.jpg", lazy_url: "/img/book3/layerPaintings/LL'ESPOIR EN ROSE 60X60.jpg", name: "L'ESPOIR EN ROSE 60X60"},
        {url: "/img/book3/layerPaintings/Puissance de la vie 50X100.jpg", lazy_url: "/img/book3/layerPaintings/LPuissance de la vie 50X100.jpg", name: "Puissance de la vie 50X100"},
        {url: "/img/book3/layerPaintings/FUSION 60X60.jpg", lazy_url: "/img/book3/layerPaintings/LFUSION 60X60.jpg", name: "FUSION 60X60"},
        {url: "/img/book3/layerPaintings/FONDS BLEUS 60X60.jpg", lazy_url: "/img/book3/layerPaintings/LFONDS BLEUS 60X60.jpg", name: "FONDS BLEUS 60X60.jpg"},
      ],
      images5: [
        {url: "/img/book3/layerPaintings/Réflexion 50x50.jpg", lazy_url: "/img/book3/layerPaintings/LRéflexion 50x50.jpg", name: "Réflexion 50x50"},
        {url: "/img/book3/layerPaintings/GIRAFON 50X50.jpg", lazy_url: "/img/book3/layerPaintings/LGIRAFON 50X50.jpg", name: "GIRAFON 50X50"},
      ],
      images6: [
        //{url: "/img/book3/layerPaintings/Coquillage.jpg", lazy_url: "/img/book3/layerPaintings/LCoquillage.jpg", name: "Coquillage"},
        {url: "/img/book3/layerPaintings/Intimité cachée 55X70.jpg", lazy_url: "/img/book3/layerPaintings/LIntimité cachée 55X70.jpg", name: "Intimité cachée 55X70"},
        {url: "/img/book3/layerPaintings/EAU FOLLE 50X70.jpg", lazy_url: "/img/book3/layerPaintings/LEAU FOLLE 50X70.jpg", name: "EAU FOLLE 50X70"},
        {url: "/img/book3/layerPaintings/DOUCEUR OMBRE ET LUMIERE 65X53.jpg", lazy_url: "/img/book3/layerPaintings/LDOUCEUR OMBRE ET LUMIERE 65X53.jpg", name: "DOUCEUR OMBRE ET LUMIERE 65X53"},
      ],
      images7: [
        {url: "/img/book3/layerPaintings/Dance du pavot 80X80.jpg", lazy_url: "/img/book3/layerPaintings/LDance du pavot 80X80.jpg", name: "Dance du pavot 80X80"},
        {url: "/img/book3/layerPaintings/Les pavots du sommeil 80X80.jpg", lazy_url: "/img/book3/layerPaintings/LLes pavots du sommeil 80X80.jpg", name: "Les pavots du sommeil 80X80"},
        {url: "/img/book3/layerPaintings/L'homme aux yeux d'or 50X50.jpg", lazy_url: "/img/book3/layerPaintings/LL'homme aux yeux d'or 50X50.jpg", name: "L'homme aux yeux d'or 50X50"},
      ],
      images8: [
        {url: "/img/book3/layerPaintings/Tristesse d'une abeille  54X72.jpg", lazy_url: "/img/book3/layerPaintings/LTristesse d'une abeille  54X72.jpg", name: "Tristesse d'une abeille 54X72"},
        {url: "/img/book3/layerPaintings/Le Paon 41x22.jpg", lazy_url: "/img/book3/layerPaintings/LLe Paon 41x22.jpg", name: "Le Paon 41x22"},
        {url: "/img/book3/layerPaintings/Echange constructif 40x40.jpg", lazy_url: "/img/book3/layerPaintings/LEchange constructif 40x40.jpg", name: "Echange constructif 40x40"},
      ],
      images9: [
        {url: "/img/book3/layerPaintings/Le temple de la méditation 40X40.jpg", lazy_url: "/img/book3/layerPaintings/LLe temple de la méditation 40X40.jpg", name: "Le temple de la méditation 40X40"},
        {url: "/img/book3/layerPaintings/THE TABLE...80X40.jpg", lazy_url: "/img/book3/layerPaintings/LTHE TABLE...80X40.jpg", name: "THE TABLE...80X40"},
        {url: "/img/book3/layerPaintings/Paysage secret 100X50.jpg", lazy_url: "/img/book3/layerPaintings/LPaysage secret 100X50.jpg", name: "Paysage secret 100X50"},
        {url: "/img/book3/layerPaintings/TOURMENTE 80X40.jpg", lazy_url: "/img/book3/layerPaintings/LTOURMENTE 80X40.jpg", name: "TOURMENTE 80X40"},
        {url: "/img/book3/layerPaintings/LE TIMBRE 60X40.jpg", lazy_url: "/img/book3/layerPaintings/LLE TIMBRE 60X40.jpg", name: "LE TIMBRE 60X40"},
      ],
      images10: [
        {url: "/img/book3/layerPaintings/DAVID 100X50.jpg", lazy_url: "/img/book3/layerPaintings/LDAVID 100X50.jpg", name: "DAVID 100X50"},
        {url: "/img/book3/layerPaintings/L'HOMME DETENDU 50X60 (2).jpg", lazy_url: "/img/book3/layerPaintings/LL'HOMME DETENDU 50X60 (2).jpg", name: "L'HOMME DETENDU 50X60"},
      ],
      images11: [
        {url: "/img/book3/layerPaintings/Auto portrait74X54.jpg", lazy_url: "/img/book3/layerPaintings/LAuto portrait74X54.jpg", name: "Auto portrait 74X54 "},
        {url: "/img/book3/layerPaintings/Evolution 40X40.jpg", lazy_url: "/img/book3/layerPaintings/LEvolution 40X40.jpg", name: "Evolution 40X40"},
        {url: "/img/book3/layerPaintings/IMG_1303.jpg", lazy_url: "/img/book3/layerPaintings/LIMG_1303.jpg", name: ""},
        {url: "/img/book3/layerPaintings/La réalité du quotidien 60X60.jpg", lazy_url: "/img/book3/layerPaintings/LLa réalité du quotidien 60X60.jpg", name: "La réalité du quotidien 60X60 "},
        {url: "/img/book3/layerPaintings/MES PETITS BONHEURS 74X35.jpg", lazy_url: "/img/book3/layerPaintings/LMES PETITS BONHEURS 74X35.jpg", name: "MES PETITS BONHEURS 74X35"},
      ],
    }
  },
  mounted() {
    this.$vuetify.goTo(0);
  },
  // created() {
  //   console.table(this.$store.getters.getMongoose)
  // }
}
</script>

<style scoped>

</style>